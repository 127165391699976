
import SupportMessage from "@/components/SupportMessage"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import Vue from "vue"
import { useAccountStore } from "@/stores/account"

export default Vue.extend({
  components: { SupportMessage },
  layout: "widget",
  async asyncData({ app, redirect }) {
    try {
      await EvercamApi.users.getCredentials()
      if (useAccountStore().token) {
        redirect("/v2/projects")
      }
    } catch (e) {
      app.$errorTracker.save(e)
    }
  },
  data() {
    return {
      seconds: 5,
    }
  },
  head() {
    return {
      title: "Evercam | Server Down",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  mounted() {
    this.countdown()
  },
  methods: {
    checkAPI() {
      EvercamApi.apiStatus
        .getApiStatus()
        .then(() => {
          if (useAccountStore().token) {
            this.$router.push("/v2/projects")
          } else {
            this.$router.push("/v2/users/signin")
          }
        })
        .catch(() => {
          return this.countdown(5)
        })
    },
    countdown(seconds: number = 5) {
      if (this.seconds > 0) {
        this.seconds = seconds - 1
        this.$setTimeout(() => this.countdown(this.seconds - 1), 1000)
      } else {
        this.checkAPI()
      }
    },
  },
})
